import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Sidebar = lazy(() => import("./components/Sidebar"));
// Branches
const BranchView = lazy(() => import("./pages/BranchView"));
const BranchAdd = lazy(() => import("./pages/BranchAdd"));
const BranchEdit = lazy(() => import("./pages/BranchEdit"));

// Users
const UserView = lazy(() => import("./pages/Users"));
const UserAdd = lazy(() => import("./pages/UserAdd"));
// Categories
const CategoryView = lazy(() => import("./pages/CategoryView"));
const CategoryAdd = lazy(() => import("./pages/CategoryAdd"));
const CategoryEdit = lazy(() => import("./pages/CategoryEdit"));
const SubcategoryView = lazy(() => import("./pages/SubcategoryView"));
const SubcategoryAdd = lazy(() => import("./pages/SubcategoryAdd"));
const SubcategoryEdit = lazy(() => import("./pages/SubcategoryEdit"));
// Items
const Items = lazy(() => import("./pages/ItemView"));
const ItemAdd = lazy(() => import("./pages/ItemAdd"));
const ItemEdit = lazy(() => import("./pages/ItemEdit"));
//Orders
const OrdersView = lazy(() => import("./pages/OrderView"));
const Order = lazy(() => import("./pages/Order"));
const OrderEdit = lazy(() => import("./pages/OrderEdit"));
// Coupons
const Coupons = lazy(() => import("./pages/CouponView"));
const CouponAdd = lazy(() => import("./pages/CouponAdd"));
const CouponEdit = lazy(() => import("./pages/CouponEdit"));

const Page404 = lazy(() => import("./pages/NotFound"));
const Login = lazy(() => import("./pages/Login"));
const Forgot = lazy(() => import("./pages/Forgot"));
const OrderHistory = lazy(() => import("./pages/OrderHistory"));
const Pdf = lazy(() => import("./pages/Pdf"));
const PaymentMethod = lazy(() => import("./pages/Reports/paymenthMethod"));
const Analysis = lazy(() => import("./pages/Reports/Analysis"));
const Export = lazy(() => import("./pages/Reports/UserExport"));
const DiscountSettleReport = lazy(() => import("./pages/Reports/discountSettleReport"));
const OpenCloseReport = lazy(() => import("./pages/Reports/OpenCloseShop.jsx"));
const SellingShopReport = lazy(() => import("./pages/Reports/sellingShopReport.jsx"));
// Admin setting
const AdminSetting = lazy(() => import("./pages/AdminSetting"));
const PasswordReset = lazy(() => import("./pages/PasswordReset.js"));
const Router = () => {
  const parse = JSON.parse(localStorage.getItem("myuser"));
  const branch = parse?.details?.branch;
  const userType = parse?.details?.userType;
  return (
    <>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          {userType !== "counterAttendant" ? (
            <Route element={<Sidebar />}>
              <Route exact path="/" element={<Dashboard />} />
              {/* Branches  */}

              {branch === "superadmin" ? (
                <>
                  <Route path="/branches" element={<BranchView />} />
                  <Route path="/branch-add" element={<BranchAdd />} />
                  <Route path="/branch-edit/:id" element={<BranchEdit />} />
                </>
              ) : (
                ""
              )}
              {/* Users      */}
              {branch !== "superadmin" ? (
                <>
                  <Route path="/users" element={<UserView />} />
                  <Route path="/user-add" element={<UserAdd />} />
                </>
              ) : (
                ""
              )}
              {/* Categories  */}
              <Route path="/categories" element={<CategoryView />} />
              <Route path="/category-add" element={<CategoryAdd />} />
              <Route path="/category-edit/:id" element={<CategoryEdit />} />
              <Route path="/subcategories" element={<SubcategoryView />} />
              <Route path="/subcategory-add" element={<SubcategoryAdd />} />
              <Route
                path="/subcategory-edit/:id"
                element={<SubcategoryEdit />}
              />
              {/* Items  */}
              <Route path="/items" element={<Items />} />
              <Route path="/item-add" element={<ItemAdd />} />
              <Route path="/item-edit/:id" element={<ItemEdit />} />
              {/* Orders  */}
              <Route path="/orders" element={<OrdersView />} />
              <Route path="/order-history" element={<OrderHistory />} />
              {/* Coupons  */}
              <Route path="/coupons" element={<Coupons />} />
              <Route path="/coupon-add" element={<CouponAdd />} />
              <Route path="/coupon-edit/:id" element={<CouponEdit />} />

              {/* Pdf test  */}
              <Route path="/pdf" element={<Pdf />} />
              {/* Admin setting  */}
              <Route path="/admin-setting" element={<AdminSetting />} />
              <Route path="/reset-password" element={<PasswordReset />} />
              {/* Reports */}
              <Route path="/report/paymentType" element={<PaymentMethod />} />
              <Route path="/report/analysis" element={<Analysis />} />
              <Route path="/report/user-export" element={<Export />} />
              <Route path="/report/discount-settle-report" element={<DiscountSettleReport />} />
              <Route path="/report/open-close-report" element={<OpenCloseReport />} />
              <Route path="/report/shop-report" element={<SellingShopReport />} />
            </Route>
          ) : (
            ""
          )}

          <Route path="/order" element={<Order />} />
          <Route path="/order-edit/:orderId" element={<OrderEdit />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Router;
