import React, { useEffect } from 'react'
import Router from './routes';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('myuser')) {
      navigate('/login')
    }
    else if (localStorage.getItem('myuser')) {
      let parse = JSON.parse(localStorage.getItem('myuser'))
      const options = {
        method: 'POST',
        url: `${process.env.REACT_APP_API_HOST}/api/auth/verify`,
        headers: { apiKey: process.env.REACT_APP_API_KEY },
        data: {
          token: parse.token
        }
      };

      axios.request(options).then(function (response) {
        if (response.data.success) {
          // navigate('/')
        }
        else {
          localStorage.removeItem('myuser')
          navigate('/login')
        }
      }).catch(function (error) {
        // console.error(error);
        localStorage.removeItem('myuser')
        navigate('/login')
      });
    }

  }, [])


  return (
    <>
      <Router />
    </>
  )
}

export default App